<template>
  <my-modal>
    <CardForm :titulo="'Agregar admin'">
      <DataList
      modal="true"
        ref="datatable"
        url="admins"
        :fields="fields"
        class="mb-3"
        :toCuil="toCuil"
        :rowClick="agregar"
      />
    </CardForm>
  </my-modal>
</template>
<script>
import MyModal from "@/components/layouts/MyModal";
import CardForm from '@/components/layouts/CardForm';
import DataList from '@/components/layouts/DataList';
import Axios from 'axios';
import swal from 'sweetalert2';
export default {
  name: "ModalNuevoAdmin",
  components: {
    MyModal,
    CardForm,
    DataList,
  },
  data() {
    return {
      fields: [
        {
          name: "apellido",
          label: "",
          type: "title",
          class: "text-primary",
        },
        {
          name: "nombre",
          label: "",
          type: "title",
          class: "text-primary",
          prepend: ",",
        },
        {
          name: "tipo",
          label: "TIPO",
          type: "field",
          class: "text-success",
        },
        {
          name: "cuil",
          label: "CUIL",
          type: "field",
          class: "text-secondary",
        },
        {
          name: "email",
          label: "Email",
          type: "field",
          class: "text-secondary",
        },
        {
          name: "whatsapp",
          label: "Whatsapp",
          type: "field",
          class: "text-secondary",
        },
      ],
      toCuil: ["cuil"],
    };
  },
  props: {
    institucion_id: {
      required: true,
      value: null,
    },
  },
  methods:{
      agregar(user){
          let params = {
              user_id : user.id,
              institucion_id : this.institucion_id
          }
          Axios.post('institucion-administrador', params)
          .then((r) => {
              if(r.data.success){
                  swal.fire('Exito', 'Se añadió con éxito', 'success')
                  this.$emit("userAdded", user);
              }else{
                  swal.fire('Error', 'No se ha podido guardar', 'error')
              }
          })
          .catch( (c) => {
               swal.fire('Error', '<p>No se ha podido guardar</p><p>'+c+'</p>', 'error')
          })
      }
  }
};
</script>