<template>
    <ul class="list-group list-group-flush my-2" >
      <li class="list-group-item  hoverable" v-for="liquidacion in liquidaciones" :key="liquidacion.id" @click="$router.push({name:'VerLiquidacion', params:{id:liquidacion.id}})">
        <div class="widget-content p-0">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <h5 class="widget-heading text-success">
               {{liquidacion.id}}) {{ liquidacion.periodo }} - {{ liquidacion.tipo_liquidacion }}
              </h5>
            </div>
          </div>
        </div>
      </li>
    </ul>
</template>
<script>
export default {
    name:'ListaLiquidaciones',
    props:{
        liquidaciones:{
            type: Array,
            value:[]
        }
    }
}
</script>