<template>
  <div class="card" v-if="institucion.razon_social">
    <div class="card-body">
      <h4 class="">{{ institucion.razon_social }}</h4>
      <hr />
      <div class="row">
        <div class="col-auto">
          <span class="text-bold"><b>ID: </b> </span>
          <span class="text-primary">{{ institucion.id }}</span>
        </div>
        <div class="col-auto">
          <span class="text-bold"><b>CUIT: </b></span>
          <span class="text-primary">{{ institucion.cuit | cuil }}</span>
        </div>
        <div class="col-12"></div>
        <div class="col-auto">
          <span class="text-bold"><b>Domicilio: </b></span>
          <span class="text-secondary"
            >{{ institucion.domicilio }}, {{ institucion.localidad }},
            {{ institucion.provincia }}</span
          >
        </div>
        <div class="col-auto">
          <span class="bold"><b>CP: </b></span>
          <span class="text-secondary">{{ institucion.codigo_postal }}</span>
        </div>
        <div class="col-12"></div>
        <div class="col-auto">
          <span class="bold"><b>Email: </b></span>
          <span class="text-secondary">{{ institucion.email }}</span>
        </div>
        <div class="col-auto">
          <span class="bold"><b>Telefono: </b></span>
          <span class="text-secondary">{{ institucion.telefono }}</span>
        </div>
        <div class="col-12"></div>
        <div class="col-auto">
          <span class="bold"><b>Estado: </b></span>
          <span class="text-secondary">{{ institucion.estado }}</span>
        </div>
        <div class="col-auto">
          <span class="bold"><b>Creado: </b></span>
          <span class="text-secondary">{{
            institucion.created_at | formatDate()
          }}</span>
        </div>
      </div>
      <h4 class="my-3">Administradores</h4>
      <button class="btn btn-info mb-3" @click="add = true">Nuevo administrador</button>
      <lista-admin-institucion :admins="institucion.admins"></lista-admin-institucion>
      <h4 class="my-3">Liquidaciones</h4>
      <lista-liquidaciones :liquidaciones="institucion.liquidaciones"></lista-liquidaciones>
    </div>
    <ModalNuevoAdmin v-if="add" :institucion_id="institucion.id" @userAdded="agregarUser"></ModalNuevoAdmin>
  </div>
</template>
<script>
import Institucion from "@/models/institucion";
import Axios from "axios";
import ListaAdminInstitucion from "@/components/ListaAdminInstitucion.vue";
import ListaLiquidaciones from "@/components/ListaLiquidaciones.vue";
import ModalNuevoAdmin from '@/views/instituciones/ModalNuevoAdmin';
export default {
  name: "VerInstitucion",
  components: {
    ListaAdminInstitucion,
    ListaLiquidaciones,
    ModalNuevoAdmin
  },
  data() {
    return {
      institucion: new Institucion(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ),
      add:false
    };
  },
  props: {
    iconoTitulo: String,
    titleCard: String,
    toCreateAction: String,
    toCreateText: String,
  },
  mounted() {
    this.institucion.id = this.$route.params.id;
    this.getInstitucion();
  },
  methods: {
    getInstitucion() {
      Axios.get("institucion/" + this.institucion.id)
        .then((res) => {
          if (res.data.success) {
            this.institucion = res.data.institucion;
          }
        })
        .catch((c) => {
          console.log(c);
        });
    },
    agregarUser(user){
        this.institucion.admins.push(user);
        this.add = false
    }
  },
};
</script>