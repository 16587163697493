<template>
    
    <ul class="list-group list-group-flush">
      <li class="list-group-item  hoverable" v-for="admin in admins" :key="admin.id" @click="$router.push({name:'VerUsuario', params:{id:admin.id}})">
        <div class="widget-content p-0">
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <h5 class="widget-heading text-primary">
               {{ admin.id }}) {{ admin.nombre }}, {{admin.apellido}}
              </h5>
            </div>
          </div>
          <div class="widget-content-wrapper">
            <div class="widget-content-left">
              <div class="widget-subheading opacity-9">
                <span class="text-secondary"> CUIL: </span>{{ admin.cuil }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
</template>
<script>
export default {
    name:'ListaAdminInstitucion',
    props:{
        admins:{
            type: Array,
            value: []
        }
    }

}
</script>